<template>
    <div>
        <user-list-add-new
            :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            :department-options="departmentOptions"
            @refetch-data="refetchData"
        />

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
                            <b-button variant="primary" @click="isAddNewUserSidebarActive = true">
                                <span class="text-nowrap">Add User</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="fetchUsers"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: User -->
                <template #cell(logo)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-img :src="data.item.logoUrl" width="180" />
                        </template>
                    </b-media>
                </template>

                <!-- Column: Role -->
                <template #cell(name)="data">
                    <div class="text-nowrap">
                        <b-link
                            :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                            class="font-weight-bold d-block text-nowrap"
                        >
                            {{ data.item.name }}
                        </b-link>
                    </div>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                    <b-badge
                        pill
                        :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                        class="text-capitalize"
                    >
                        {{ data.item.status }}
                    </b-badge>
                </template>

                <!-- Column: Issued Date -->
                <template #cell(createDate)="data">
                    <span class="text-nowrap text-capitalize">
                        {{ data.item.createDate | formatDate }}
                    </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item :id="`user-row-${data.item.id}-sync-icon`" @click="syncuser(data.item.id)">
                            <feather-icon icon="RefreshCwIcon" />
                            <span class="align-middle ml-50">Sync</span>
                        </b-dropdown-item>

                        <b-dropdown-item disabled>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-row v-if="totalUsers === 0" class="mx-2 d-flex justify-content-center"
                ><span class="font-weight-bold">No Records Found</span></b-row
            >
            <div v-if="tableLoading" class="text-center my-1">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            </div>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"
                            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
                        >
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol, // BFormInput,
    BButton,
    BTable,
    BMedia,
    BSpinner,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import useUserList from "./useUserList";
import userStoreModule from "../userStoreModule";
import UserListAddNew from "./UserListAddNew.vue";

export default {
    components: {
        UserListAddNew,
        BImg,
        BCard,
        BRow,
        BCol,
        // BFormInput,
        BSpinner,
        BButton,
        BTable,
        BMedia,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = "admin-user";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
            store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            }
        });

        const isAddNewUserSidebarActive = ref(false);

        const departmentOptions = [
            {
                label: "Technology",
                value: "Technology",
            },
            {
                label: "Marketing",
                value: "Marketing",
            },
            {
                label: "Sales",
                value: "Sales",
            },
            {
                label: "Product",
                value: "Product",
            },
        ];

        const {
            fetchUsers,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,
            tableLoading,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useUserList();

        return {
            // Sidebar
            isAddNewUserSidebarActive,

            fetchUsers,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,
            tableLoading,
            // Filter
            avatarText,

            departmentOptions,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
