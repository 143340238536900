<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="val => $emit('update:is-add-new-user-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Add New User</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- Full Name -->
                    <validation-provider #default="validationContext" name="Full Name" rules="required">
                        <b-form-group label="Full Name" label-for="full-name">
                            <b-form-input
                                id="full-name"
                                v-model="userData.fullName"
                                autofocus
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="Calvin Klein"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Email -->
                    <validation-provider #default="validationContext" name="email" rules="required|email">
                        <b-form-group label="Email" label-for="email">
                            <b-form-input
                                id="email"
                                v-model="userData.email"
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="calvin@klein.com"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Password -->
                    <validation-provider #default="validationContext" name="Password" rules="required">
                        <b-form-group label="Password" label-for="password">
                            <b-form-input
                                id="password"
                                v-model="userData.password"
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="VeryStrongPwd"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Phone -->
                    <validation-provider #default="validationContext" name="Phone" rules="required">
                        <b-form-group label="Phone" label-for="phone">
                            <b-form-input
                                id="phone"
                                v-model="userData.phone"
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="+90 555 555 55 55"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Department -->
                    <validation-provider #default="validationContext" name="Department" rules="required">
                        <b-form-group
                            label="Department"
                            label-for="department"
                            :state="getValidationState(validationContext)"
                        >
                            <v-select
                                v-model="userData.department"
                                :options="departmentOptions"
                                :reduce="val => val.value"
                                :clearable="false"
                                input-id="department"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            Add
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancel
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isAddNewUserSidebarActive",
        event: "update:is-add-new-user-sidebar-active",
    },
    props: {
        isAddNewUserSidebarActive: {
            type: Boolean,
            required: true,
        },
        departmentOptions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            required,
            alphaNum,
            email,
        };
    },
    setup(props, { emit }) {
        const blankuserData = {
            name: "",
            url: "",
            type: null,
            api_key: "",
            api_secret: "",
            logo_url: "",
        };

        const userData = ref(JSON.parse(JSON.stringify(blankuserData)));
        const resetuserData = () => {
            userData.value = JSON.parse(JSON.stringify(blankuserData));
        };

        const onSubmit = () => {
            store.dispatch("admin-user/addUser", userData.value).then(() => {
                emit("refetch-data");
                emit("update:is-add-new-user-sidebar-active", false);
            });
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetuserData);

        return {
            userData,
            onSubmit,

            refFormObserver,
            getValidationState,
            resetForm,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
